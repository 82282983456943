import React from 'react'
import PropTypes from 'prop-types'
import IntroTitle from '../IntroTitle/IntroTitle'
import Lead from '../Lead/Lead'
import Timetable from '../Timetable/Timetable'
import styles from './IntroTimetable.module.scss'

const IntroTimetable = props => (
  <div className={styles.wrap}>
    <div className={styles.grid}>
      <div className={styles.titleColumn}>
        <IntroTitle text={props.title} introTimetable />
        <Lead text={props.lead} noSpacing />
      </div>
      <div className={styles.timetableColumn}>
        <Timetable data={props.timetable} locale={props.locale} />
      </div>
    </div>
  </div>
)

IntroTimetable.propTypes = {
  title: PropTypes.string.isRequired,
  lead: PropTypes.string.isRequired,
  timetable: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
}

export default IntroTimetable
