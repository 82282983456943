import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from '../Modal/Modal'
import styles from './Timetable.module.scss'
import Icon from '../Icon/Icon'

const trans = {
  book: {
    "es": "Reserva",
    "en": "Book",
    "ca": "Reserva",
    "fr": "Réserve",
  }
}

class Timetable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showModal: false
    }
  }

  toggleModal () {
    if (this.state.showModal) {
      this.setState({
        showModal: false
      })
    } else {
      this.setState({
        showModal: true
      })
    }
  }

  closeModal () {
    this.setState({
      showModal: false
    })
  }

  render () {
    const bookData = this.props.data.book
    let book = null
    if (bookData) {
      book = <Book data={bookData} locale={this.props.locale} />
    }
    return (
      <div className={styles.wrap}>
        <div className={styles.grid}>
          <div className={styles.columnLeft}>
            <h2 className={styles.title}>
              {this.props.data.title}
            </h2>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: this.props.data.content }}
            />
          </div>
          <div className={styles.columnRight}>
            {book}
          </div>
        </div>
        {this.props.data.cancellation &&
          <div>
            <button
              type='button'
              className={styles.cancellationButton}
              onClick={() => { this.toggleModal() }}
            >
              {this.props.data.cancellation.title}
            </button>
            <Modal
              isOpen={this.state.showModal}
              onRequestClose={() => { this.closeModal() }}
            >
              <div className={styles.cancellationWrap}>
                <h3 className={styles.cancellationTitle}>
                  {this.props.data.cancellation.title}
                </h3>
                <div
                  className={styles.cancellationContent}
                  dangerouslySetInnerHTML={{ __html: this.props.data.cancellation.content }}
                />
              </div>
            </Modal>
          </div>
        }
      </div>
    )
  }
}

Timetable.propTypes = {
  data: PropTypes.object.isRequired
}

const Book = props => (
  <div className={styles.book}>
    <h3 className={styles.title}>
      {props.data.title}
    </h3>
    { props.data.whatsapp_link && (
      <a href={props.data.whatsapp_link} className={styles.whatsapp}>
        <Icon name="whatsapp" role="presentation" />
        <span>WhatsApp</span>
      </a>
    )}
    <ul className={styles.bookList}>
      {props.data.list.map(({item}, i) => {
        return (
          <li key={i}>
            <BookItemContent item={item} />
          </li>
        )
      })}
    </ul>
    {props.data.reservations_link && (
      <a href={props.data.reservations_link} className={styles.reservations_link}>{trans.book[props.locale]}</a>
    )}
  </div>
)

const BookItemContent = props => {
  let content = null
  if (props.item.link) {
    content = (
      <a href={props.item.link}>
        {props.item.text}
      </a>
    )
  } else {
    content = <span>{props.item.text}</span>
  }
  return content
}

export default Timetable
